import styles from './index.module.scss';
import SupplementIcon from '../../../../shared/assets/SupplementIcon';
import Button from '../../../../shared/ui/Button';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import { useAppSelector, useAppDispatch } from '../../../../shared/store';
import { getAuthStatus } from '../../../session';
import { changeModalVisibleStatus } from '../../../authModal';
import Modal from '../../../../shared/ui/Modal';
import { useCreateModalState } from '../../../../shared/hooks/modal';
import AlertModal from '../../../../shared/ui/AlertModal';
import { ContestDetailTabs } from '../ContestDetailTabs';
import { RuleCard } from '../../../../shared/ui/RuleCard';
import { IContest } from '../../model';
import { IContestCard } from '../../model';

interface IContestDetail {
    contest: IContest;
    createGalleryItemForm: React.ReactNode;
}

export const ContestDetail: React.FC<IContestDetail> = ({ contest, createGalleryItemForm }) => {
    const contestEndDate = new Date(contest.end_date).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
    });
    const tabsData = [
        {
            id: '1',
            tabTitle: 'О конкурсе',
            tabContent: <ContestDescription contest={contest} />,
            isActive: true,
        },
        {
            id: '2',
            tabTitle: 'Сроки',
            tabContent: <ContestDeadlines contestEndDate={contestEndDate} contest={contest} />,
            isActive: true,
        },
        {
            id: '3',
            tabTitle: 'Требования',
            tabContent: <ContestConditions contest={contest} />,
            isActive: contest.condition_description,
        },
    ].filter((el) => el.isActive);

    const { isModalOpen, toggleModalStatus } = useCreateModalState();
    const { isModalOpen: isAlertlOpen, toggleModalStatus: toggleAlertStatus } =
        useCreateModalState();

    const isAuth = useAppSelector(getAuthStatus);

    const dispatch = useAppDispatch();

    const isContestEnd = new Date(contest.end_date).getTime() > Date.now();

    const viewForm = () => {
        if (isContestEnd) {
            isAuth ? toggleModalStatus() : dispatch(changeModalVisibleStatus());
        }
    };

    const redirectToResult = () => {
        contest.results_link && window.open(contest.results_link);
    };

    const isContestStart = new Date(contest.start_date).getTime() < Date.now();

    return (
        <>
            <div className={styles.contest_detail}>
                <div
                    className={styles.contest_detail_header}
                    style={{
                        backgroundImage: `url(${BASE__MEDIA__URL + contest.review_image_url})`,
                    }}
                >
                    <div className={styles.contest_detail_header_additional_container}>
                        <div className={styles.contest_detail_header_rubrics}>{contest.rubric}</div>
                        {contest.document_url && (
                            <a
                                className={styles.contest_detail_header_suplement}
                                href={`${BASE__MEDIA__URL}${contest.document_url}`}
                                target="_blank"
                            >
                                <div>Положение</div>
                                <SupplementIcon className={styles.contest_detail_header_icon} />
                            </a>
                        )}
                    </div>
                    <h2 className={styles.contest_detail_header_title}>{contest.name}</h2>
                    <time className={styles.contest_detail_header_time}>
                        Прием заявок до {contestEndDate}
                    </time>
                    <div className={styles.contest_detail_header_actions_container}>
                        {isContestStart && (
                            <Button
                                className={styles.contest_detail_header_btn}
                                text={isContestEnd ? 'Подать работу' : 'Регистрация окончена'}
                                type="submit"
                                variant="white"
                                onClick={viewForm}
                            />
                        )}
                        {contest.results_link && (
                            <Button
                                className={styles.contest_detail_header_btn}
                                text={'Отслеживать'}
                                type="submit"
                                variant="white"
                                onClick={redirectToResult}
                            />
                        )}
                    </div>
                </div>
                <div className={styles['contest_detail_body']}>
                    <ContestDetailTabs tabsData={tabsData} />
                </div>
            </div>
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    setStatus={toggleAlertStatus}
                    className={styles['contest-detail__modal']}
                >
                    {createGalleryItemForm}
                </Modal>
            )}
            {isAlertlOpen && (
                <AlertModal
                    isOpen={isAlertlOpen}
                    toggleAlertStatus={toggleAlertStatus}
                    title="Подтвердить закрытие формы?"
                    confirm={toggleModalStatus}
                />
            )}
        </>
    );
};

interface IContestInfo {
    contest: IContest;
}

const ContestCards: React.FC<IContestInfo> = ({ contest }) => {
    return (
        <ul className={styles.contest_description_cards_list}>
            {contest.contest_card.map((el, index) => (
                <li key={index} className={styles.contest_description_cards_list_item}>
                    <RuleCard variant="orange" className={styles.card_content_container}>
                        <div className={styles.card_header}>
                            <img
                                src={BASE__MEDIA__URL + el.image_url}
                                alt=""
                                className={styles.card_header_img}
                            />
                            <h4>{el.name}</h4>
                        </div>
                        <div className={styles.card_body}>{el.description}</div>
                    </RuleCard>
                </li>
            ))}
        </ul>
    );
};

interface IContestCardInfoUi {
    contestCard: IContestCard;
}

export const ContestCardInfoUi: React.FC<IContestCardInfoUi> = ({ contestCard }) => {
    return (
        <RuleCard variant="orange" className={styles.card_content_container}>
            <div className={styles.card_header}>
                <img
                    src={BASE__MEDIA__URL + contestCard.image_url}
                    alt=""
                    className={styles.card_header_img}
                />
                <h4>{contestCard.name}</h4>
            </div>
            <div className={styles.card_body}>{contestCard.description}</div>
        </RuleCard>
    );
};

const ContestDescription: React.FC<IContestInfo> = ({ contest }) => {
    return (
        <div className={styles.contest_description}>
            <h3 className={styles.contest_description_title}>О конкурсе: </h3>
            <div className={styles.contest_description_text}>{contest.description}</div>
            <h3 className={styles.prize_info_title}>{contest.prize_info} </h3>
            <ContestCards contest={contest} />
        </div>
    );
};

interface IContestDeadlines {
    contest: IContest;
    contestEndDate: string;
}

const ContestDeadlines: React.FC<IContestDeadlines> = ({ contest, contestEndDate }) => {
    return (
        <div className={styles.contest_description}>
            <h3 className={styles.contest_description_title}>Сроки проведения: </h3>
            <div className={styles.deadline_info}> {contest.deadline_description}</div>
            <ContestCards contest={contest} />
        </div>
    );
};

const ContestConditions: React.FC<IContestInfo> = ({ contest }) => {
    return (
        <div className={styles.contest_description}>
            <h3 className={styles.contest_description_title}>Требования</h3>
            <div className={styles.contest_description_text_wrapper}>
                {contest.condition_description}
            </div>
            <ContestCards contest={contest} />
        </div>
    );
};
