import { ISvgIcon } from '../types/icons';

const SupplementIcon: React.FC<ISvgIcon> = ({ className = '' }) => {
    return (
        <svg
            className={className}
            width="38"
            height="8"
            viewBox="0 0 38 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM37.3536 4.35355C37.5488 4.15829 37.5488 3.84171 37.3536 3.64645L34.1716 0.464466C33.9763 0.269204 33.6597 0.269204 33.4645 0.464466C33.2692 0.659728 33.2692 0.976311 33.4645 1.17157L36.2929 4L33.4645 6.82843C33.2692 7.02369 33.2692 7.34027 33.4645 7.53553C33.6597 7.7308 33.9763 7.7308 34.1716 7.53553L37.3536 4.35355ZM1 4.5H37V3.5H1V4.5Z"
                fill="currentColor"
            ></path>
        </svg>
    );
};

export default SupplementIcon;
