import React from 'react';
import styles from './index.module.scss';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import { IContest } from '../../model';
import clsx from 'clsx';

interface IContestPreview {
    toggleModalStatus?: () => void;
    bgUrl: string;
    rubrics: string;
    text: string;
    startDate?: string;
    endDate?: string;
    title: string;
    contest: IContest;
}

export const ContestPreview: React.FC<IContestPreview> = ({
    toggleModalStatus,
    bgUrl,
    rubrics,
    title,
    endDate = '',
    startDate = '',
    contest,
}) => {
    const contestEndDate = new Date(endDate).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
    });

    const getContestStatus = () => {
        if (new Date(startDate).getTime() > Date.now()) {
            return 'Скоро начнется';
        }
        if (
            new Date(startDate).getTime() < Date.now() &&
            new Date(endDate).getTime() > Date.now()
        ) {
            return `до ${contestEndDate}`;
        }
        if (new Date(endDate).getTime() < Date.now()) {
            return 'Регистрация окончена';
        }
    };

    return (
        <div className={styles['contest-preview__container']} onClick={toggleModalStatus}>
            <article
                className={clsx(
                    styles['contest-preview'],
                    new Date(endDate).getTime() < Date.now() && styles['contest-preview--inactive']
                )}
                style={{ backgroundImage: `url(${BASE__MEDIA__URL + bgUrl})` }}
            >
                <div className={styles['contest-preview__info']}>
                    <span className={styles['contest-preview__rubrics']}>{rubrics}</span>
                    <h2 className={styles['contest-preview__title']}>{title}</h2>
                </div>
                {contest.prize_info && (
                    <div className={styles['contest-preview__prize']}>
                        <h3 className={styles['contest-preview__prize_title']}>Призы: </h3>
                        <div className={styles['contest-preview__prize_description']}>
                            {contest.prize_info}
                        </div>
                    </div>
                )}
                <div className={styles['contest-preview__result-status']}>
                    <button className={styles['contest-preview__result-btn']}>
                        {/* {new Date(endDate).getTime() < Date.now() ? 'Результаты' : 'Участвовать'} */}
                        Узнать подробности
                    </button>
                    <time className={styles['contest-preview__time']}>{getContestStatus()}</time>
                </div>
            </article>
        </div>
    );
};
